#dash1-widget-performance .circle {
  border: 2px solid #fff;
  box-shadow: 0 0 0 1px transparent;
  box-sizing: content-box; }
  #dash1-widget-performance .circle.circle.bg-primary {
    box-shadow: 0 0 0 1px #39527b;
    background: #39527b; }
  #dash1-widget-performance .circle.circle.bg-success {
    box-shadow: 0 0 0 1px #60c84c;
    background: #60c84c; }
  #dash1-widget-performance .circle.circle.bg-danger {
    box-shadow: 0 0 0 1px #ff7473;
    background: #ff7473; }

/* timeline widget */
#dash1-widget-timeline .tl-item {
  position: relative;
  padding-left: 1.5rem;
  padding-bottom: 1.5rem; }
  #dash1-widget-timeline .tl-item::before {
    border-left-width: 1px;
    border-left-style: solid;
    content: '';
    height: calc(100% - 13px);
    top: 13px;
    left: 0;
    bottom: 0;
    position: absolute; }
  #dash1-widget-timeline .tl-item::after {
    content: '';
    width: 13px;
    height: 13px;
    top: 0;
    left: -6px;
    position: absolute;
    border-width: 1px;
    border-style: solid;
    border-radius: 100%; }
  #dash1-widget-timeline .tl-item .tl-date {
    font-size: 12px;
    color: #868e96; }
  #dash1-widget-timeline .tl-item .tl-text {
    margin: 0; }
  #dash1-widget-timeline .tl-item.tl-item:nth-child(4n+1)::before {
    border-left-color: #39527b; }
  #dash1-widget-timeline .tl-item.tl-item:nth-child(4n+1)::after {
    border-color: #39527b; }
  #dash1-widget-timeline .tl-item.tl-item:nth-child(4n+2)::before {
    border-left-color: #60c84c; }
  #dash1-widget-timeline .tl-item.tl-item:nth-child(4n+2)::after {
    border-color: #60c84c; }
  #dash1-widget-timeline .tl-item.tl-item:nth-child(4n+3)::before {
    border-left-color: #ffc952; }
  #dash1-widget-timeline .tl-item.tl-item:nth-child(4n+3)::after {
    border-color: #ffc952; }
  #dash1-widget-timeline .tl-item.tl-item:nth-child(4n+4)::before {
    border-left-color: #ff7473; }
  #dash1-widget-timeline .tl-item.tl-item:nth-child(4n+4)::after {
    border-color: #ff7473; }

/* Tasks widget */
#dash1-widget-tasks .list-group-item {
  padding-top: 1rem;
  padding-bottom: 1rem; }

#dash1-widget-tasks [type="checkbox"]:checked + label {
  font-style: italic;
  text-decoration: line-through;
  color: #868e96; }

#dash1-widget-tasks #new-task-form {
  padding: 1.5rem;
  position: relative; }
  #dash1-widget-tasks #new-task-form #new-task-field,
  #dash1-widget-tasks #new-task-form #new-task-submit {
    border: none;
    box-shadow: none;
    outline: none; }
  #dash1-widget-tasks #new-task-form #new-task-field {
    width: 100%;
    padding: .5rem;
    padding-left: 2.5rem;
    color: #868e96; }
  #dash1-widget-tasks #new-task-form #new-task-submit {
    background: transparent;
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    padding: .5rem 0;
    display: inline-block; }

/* Quick Actions widget */
#dash1-widget-quick-actions .list-group-item {
  padding: .75rem 0; }

@media (min-width: 576px) {
  #dash1-widget-quick-actions .section-2 {
    display: flex;
    width: 50%;
    align-items: center; } }

/* Stats Widgets */
.dash1-widget-stats .progress {
  border-radius: 0;
  margin-right: -1px;
  margin-left: -1px;
  width: calc(100% + 2px); }

.dash1-widget-stats .progress-bar {
  border-right: 4px solid rgba(0, 0, 0, 0.09); }

/* Chat widget */
#dash1-widget-chat .input-group .form-control, #dash1-widget-chat .input-group .btn {
  height: 64px;
  background-color: #fbfbfb;
  border-color: #eee;
  border-bottom: none; }
  #dash1-widget-chat .input-group .form-control, #dash1-widget-chat .input-group .form-control:focus, #dash1-widget-chat .input-group .form-control:active, #dash1-widget-chat .input-group .btn, #dash1-widget-chat .input-group .btn:focus, #dash1-widget-chat .input-group .btn:active {
    outline: none;
    box-shadow: none; }

#dash1-widget-chat .input-group .form-control {
  color: #39527b;
  font-style: italic;
  font-size: .875rem; }

#dash1-widget-chat .input-group .input-group-btn:first-child .btn {
  border-left: none; }

#dash1-widget-chat .input-group .input-group-btn:last-child .btn {
  border-right: none; }

#dash1-widget-chat .input-group .btn {
  border-radius: 0; }

#dash1-widget-chat .chat-entries {
  overflow-y: auto;
  height: 280px; }

#dash1-widget-chat .typing-indicator {
  width: 80px;
  height: 24px;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  text-align: center;
  float: right; }
  #dash1-widget-chat .typing-indicator span {
    width: 8px;
    height: 8px;
    display: inline-block;
    background-color: #868e96;
    border-radius: 100%;
    opacity: .1;
    animation: typing 1500ms infinite; }
    #dash1-widget-chat .typing-indicator span:nth-child(2) {
      animation-delay: 500ms; }
    #dash1-widget-chat .typing-indicator span:nth-child(3) {
      animation-delay: 1000ms; }

@keyframes typing {
  100% {
    opacity: 1; } }

#dash1-widget-chat .chat-entry {
  position: relative;
  margin-bottom: 1rem; }
  #dash1-widget-chat .chat-entry::after {
    display: block;
    clear: both;
    content: ""; }
  #dash1-widget-chat .chat-entry:last-of-type {
    margin-bottom: 0; }
  #dash1-widget-chat .chat-entry .chat-entry-bubble {
    padding: .5rem;
    position: relative;
    display: inline-block;
    background: #f8f9fa;
    border-radius: 0.25rem;
    margin-bottom: .5rem; }
  #dash1-widget-chat .chat-entry .chat-entry-bubble p:last-child {
    margin-bottom: 0; }
  #dash1-widget-chat .chat-entry .chat-entry-bubble:first-of-type::after {
    content: '';
    position: absolute;
    top: 8px; }
  #dash1-widget-chat .chat-entry .chat-entry-avatar {
    position: absolute;
    margin-right: 0;
    top: 0; }
  #dash1-widget-chat .chat-entry.entry-left {
    padding-left: 60px; }
    #dash1-widget-chat .chat-entry.entry-left .chat-entry-bubble {
      float: left;
      box-shadow: 1px 1px 2px #eee;
      margin-right: 2px; }
    #dash1-widget-chat .chat-entry.entry-left .chat-entry-avatar {
      left: 0; }
    #dash1-widget-chat .chat-entry.entry-left .chat-entry-bubble:first-of-type::after {
      left: -10px;
      border-right: 10px solid #f8f9fa;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent; }
  #dash1-widget-chat .chat-entry.entry-right {
    padding-right: 60px; }
    #dash1-widget-chat .chat-entry.entry-right .chat-entry-bubble {
      float: right;
      box-shadow: -1px 1px 2px #eee;
      margin-left: 2px; }
    #dash1-widget-chat .chat-entry.entry-right .chat-entry-avatar {
      right: 0; }
    #dash1-widget-chat .chat-entry.entry-right .chat-entry-bubble:first-of-type::after {
      right: -10px;
      border-left: 10px solid #f8f9fa;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent; }

/* Activities widget */
#dash1-widget-activities .chart {
  position: relative; }

#dash1-widget-activities #dash1-easypiechart-1 {
  display: inline-block;
  width: 80px;
  height: 80px;
  text-align: center; }
  #dash1-widget-activities #dash1-easypiechart-1 canvas {
    position: absolute;
    top: 0;
    left: 0; }
  #dash1-widget-activities #dash1-easypiechart-1 .center-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

@media (max-width: 575px) {
  #dash1-widget-activities .activity-counters {
    width: 100%;
    margin-top: 1.5rem; }
  #dash1-widget-activities .media-list.left-list .media:last-child {
    border-bottom: 1px solid #eee; } }

/* Products-widget */
#dash1-widget-products .item {
  position: relative;
  text-align: center; }

#dash1-widget-products .item img {
  max-width: 100%;
  height: 200px; }

#dash1-widget-products .item .price-badge {
  width: 60px;
  height: 60px;
  border-radius: 500px;
  background: #60c84c;
  border: 2px solid #fff;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  line-height: 56px;
  position: absolute;
  top: 0;
  left: 50%; }

#dash1-widget-products .owl-controls {
  width: 54px;
  margin: 0 auto;
  margin-top: 2rem; }
  #dash1-widget-products .owl-controls .owl-page {
    display: inline-block;
    margin-right: 6px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #bbb;
    cursor: pointer; }
    #dash1-widget-products .owl-controls .owl-page.active {
      background: #ddd; }

.flotTip-with-arrow {
  background: #60c84c;
  border-radius: 0.25rem;
  padding: .5rem 1rem;
  color: #fff;
  border: none; }
  .flotTip-with-arrow::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%);
    border-left: 6px solid transparent;
    border-top: 6px solid #60c84c;
    border-right: 6px solid transparent; }
